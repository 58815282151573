<template>
  <v-container fluid>
    <div class="col-12">
      <v-file-input
        v-model="file"
        counter
        show-size
        truncate-length="50"
        chips
        label="Archivos"
        accept=".png,.jpg,.pdf,.doc,.docx"
        @change="cargarArchivos"
        multiple
      >
      </v-file-input>
    </div>
    <div class="col-12">
      <v-flex text-right>
        <v-btn color="success" @click="subirArchivos(null, null, 0)">
          <v-icon fab dark> mdi-cloud-upload </v-icon> UPLOAD
        </v-btn>
      </v-flex>
    </div>
    <v-dialog
      v-model="modalCargando"
      scrollable
      persistent
      :overlay="false"
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> Obteniendo datos ... </v-card-title>

        <v-card-text>
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          >
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "cargarArchivoComponent",
  data() {
    return {
      modalCargando: false,
      file: [],
      files: [],
    };
  },
  mounted() {
    this.file = [];
  },
  methods: {
    ...mapMutations(["setArchivos"]),
    ...mapActions(["getFilesQuote", "setArchivosFlag"]),
    cargarArchivos() {
      this.file.forEach((element) => {
        if (!(this.files.filter((v) => v === element).length > 0)) {
          this.files.push(element);
        }
      });
      this.file = this.files;
      this.setArchivos(this.files);
    },
    async subirArchivos(id = null, datos = null, index) {
      this.modalCargando = true;
      if (!datos) {
        datos = this.files;
      }
      if (!id) {
        id = this.$route.params.id;
      }
      let i = index;
      if (datos.length > i) {
        let formData = new FormData();
        // var valores = null;
        formData.append("name", datos[i].name);
        formData.append("file", datos[i]);
        formData.append("id_quote", id);
        formData.append(
          "id_branch",
          JSON.parse(localStorage.getItem("branch"))
        );
        await axios
          .post(
            process.env.VUE_APP_URL_MAIN + "uploadFilesSingle",
            formData,
            {
              "Content-Type": "multipart/form-data",
              "auth-token": JSON.parse(localStorage.getItem("token")),
            }
          )
          .then((response) => {
            i++;
            if (response.data.statusBol == true) {
              this.subirArchivos(id, datos, i);
            }
          });
      } else {
        this.getFilesQuote(id);
        this.setArchivosFlag();
        this.file = [];
        setTimeout(() => {
          this.modalCargando = false;
        }, 3000);
      }
    },
  },
  computed: {
    ...mapState(["archivosFlag"]),
  },
  watch: {
    archivosFlag() {
      this.file = [];
      this.setArchivos(this.files);
    },
  },
};
</script>

<style></style>
